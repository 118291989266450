.wish-moblie-aux {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    gap: 10px;

    .wish-price {
        margin-right: unset;
    }

    .wish-link {
        background-color: rgba(255, 255, 255, 0.2);
        width: 27px;
        padding: 15px;
    }
}

.wish-edit-mobile {
    -webkit-transform: rotate(90deg);     /* Chrome and other webkit browsers */
    -moz-transform: rotate(90deg);        /* FF */
    -o-transform: rotate(90deg);          /* Opera */
    -ms-transform: rotate(90deg);         /* IE9 */
    transform: rotate(90deg);             /* W3C compliant browsers */

    img {
        width: 20px;
        padding: 10px;
        opacity: 0.6;
        margin-left: -10px;

        &.close {
            width: 30px;
        }
    }
}

.edit-popup {
    margin-top: -25px;
    color: black;
    opacity: 0.6;
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
        padding: 5px;
    }
}

.form-mobile {
    margin: 10px;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.55);

    button {
        background-color: rgba(255, 255, 255, 0.3);
        margin-top: 10px;
    }
}

.add-wish-mobile {
    // margin: 0 10px;
}

// Groups

.buyer-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 30px;
    // background-color: rgba(255, 255, 255, 0.55);
    border-radius: 5px;
    width: 100%;
}

.buyer-aux {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 20px;

    .wish-price {
        margin-right: unset;
    }

    a, div {
        align-content: center;
        background-color: rgba(255, 255, 255, 0.25);
    }
}

.reserve-box {
    border: 1px solid #000;
    opacity: 0.3;
    position: absolute;
    font-size: 12px;
    padding: 3px 5px;
    border-radius: 3px;
    margin-top: -5px;
    margin-left: -5px;
}