$mobile-size: 1050px;
$orange-detail: rgb(205, 68, 59);

.request {
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
    gap: 15px;

    margin: auto;
    width: 300px;
    padding: 20px;
    
    background-color: $orange-detail;
    border-radius: 20px;

    font-weight: 900;

    label {
        color: white;
        text-align: left;
        padding: 10px 0 0 15px;
        font-size: 12px;
    }

    input {
        font-weight: 900;
        padding: 13px 15px;
        border: 2px solid $orange-detail;
        background: transparent;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.55);
    }

    input:focus {
        outline: none;
    }

    input::placeholder {
        color: $orange-detail;
        font-weight: 500;
        opacity: 1; /* Firefox */
      }
      
    input::-ms-input-placeholder { /* Edge 12 -18 */
        color: $orange-detail;
        font-weight: 500;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%;

        button {
            margin-top: 10px;
        }
    }

    .close {
        margin-top: -10px;
        margin-bottom: 10px;
        padding: 10px 15px;
    }

    button:hover {
        background-color: rgba(255, 255, 255, 0.55);
    }
}

.edit-request button {
    &.close {
        margin: auto 0 0 0;
        width: 20px;
        padding: 5px;
    }
    margin: 10px auto 0 auto;
}

.groups {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    margin: 60px 0;
    padding: 0 0 30px 5px;
}

.no-groups {
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 20px;
}

.group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    background-color: rgb(205, 68, 59);
    box-shadow: $orange-detail 0px 5px 5px;
    border-radius: 60px;
    min-width: 200px;
    padding: 10px;

    .group-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        div {
            padding: 10px;
        }

        button {
            padding: 5px;
            display: flex;
        }
        
        img {
            opacity: 0.5;
            width: 30px;
            height: 30px;
        }
    }

    h2 {
        margin-bottom: 10px;
        color: rgba(255, 255, 255, 0.55);
        font-weight: 900;
    }

    button {
        background-color: unset;
        width: 70%;

        &:hover {
            background-color: rgba(255, 255, 255, 0.25)
        }
    }
}

.person-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    color: white;
    padding: 4px;

    button {
        width: unset;
        padding: 0;
    }

    img {
        width: 30px;
        height: 30px;
    }
}

.outside-view {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1000px;
    gap: 10px;
    margin: 20px auto;

    .wishlist-table {
        max-width: unset;
        margin: unset;
        width: 85%;

        &.remove-choice {
            width: 20%;
        }
    }

    .wish {
        padding: 20px;
        width: 90%;
    }
    .wish-link {
        padding: 15px;
    }

    .select-wish {
        gap: 10px;
        background-color: rgba(255, 255, 255, 0.55);
        border-radius: 5px;
        padding: 19px 0;

        input {
            height: 19px;
            width: 19px;
        }

        label {
            font-size: 10px;
            opacity: 0.5;
            font-weight: 400;
        }
    }
}

.reserve-button {
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: rgba(255, 255, 255, 0.55);
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    color: #000;
    font-weight: 600;
    width: 140px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.85);
    }

    img {
        width: 30px;
        height: 30px;
    }
}

.no-connections {
    margin: 15px auto;
    color: white;
}

.connection-box {
    max-height: 400px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding-right: 13px;
}

.edit-request .connect-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    color: white;

    span {
        width: 65%;
        text-align: right;    
    }

    .connect-button {
        margin: unset;
        border: 2px solid white;
        border-radius: 5px;
    }
}

@media screen and (min-width: $mobile-size) {

    .wish-reserved {
        .wish, .wish-link, .reserve-button {
            background-color: rgba(189, 230, 163, 0.9);
        }
    }

    .not-buyable {
        pointer-events: none;
        cursor: not-allowed;

        .wish, .wish-link, .reserve-button {
            opacity: 0.4;
            pointer-events: none;
            cursor: not-allowed;
        }
    }

}

@media screen and (max-width: $mobile-size) {

    .add-person {
        justify-content: unset;
    }

    .groups {
        flex-wrap: nowrap;
        overflow: scroll;
        justify-content: unset;
    }

    .wish-reserved {
        background-color: rgba(189, 230, 163, 0.9);
    }

    .not-buyable {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.4;

        .wish, .wish-link, .reserve-button {
            pointer-events: none;
            cursor: not-allowed;
        }
    }

}